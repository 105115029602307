import React, {useEffect} from 'react'
import './news.scss'
import NewsNavigation from "./NewsNavigation";
import CopyrightStrip from "../../components/CopyrightStrip/CopyrightStrip";

const Article5 = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            <div className="container-fluid news-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/news/2023-majus-31-ei-fellepes-info.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt="" />
                    </div>
                </div>
            </div>

            <NewsNavigation />

            <div className="container article-container my-5 py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>MD Táncos Casting</h1>
                        <p>Sziasztok!</p>
                        <p>Amennyiben szeretnél részt venni a május 31.-ei fizetett stadioni fellépésen, vagy a későbbi
                            MD
                            Dance Company által szervezett munkákban, úgy sok szeretettel várunk nyílt castingunkra.</p>
                        <p>Kiket várunk: Olyan aktív, tehetséges és feltörekvő táncosokat, akik újabb lehetőséget
                            keresnek
                            tánckarrierjük utján.</p>
                        <p><strong>UPDATE:</strong> A nagy igényre való tekintettel megnyitjuk a IV. casting időpontját
                            is.</p>
                        <p>Casting időpontja: 2023 március 12. Vasárnap. 13:00-15:00</p>
                        <p>Casting helyszíne: MD Dance Studio</p>
                        <p>Castingra való jelentkezés:
                            <a href="https://forms.gle/Dp3oVEPSbditL5Bx8">https://forms.gle/Dp3oVEPSbditL5Bx8</a></p>
                        <p>Casting vezető: Katona Péter</p>
                        <p>Amennyiben a fent említett időpont nem megfelelő a számodra, kérlek jelezd nekünk.</p>
                        <p>A castingon való részvétel előzetes regisztrációhoz kötött.</p>
                        <p>Üdvözlettel,<br/>
                            MD Team</p>
                    </div>
                </div>
            </div>

            <CopyrightStrip />
        </>
    )
}

export default Article5