import React, {useEffect} from 'react'
import './Home.scss';
import WOW from 'wowjs'
import HomePageHeader from "./components/HomePageHeader/HomePageHeader";
import WhoAreWe from "./components/WhoAreWe/WhoAreWe";
import HowWeWork from "./components/HowWeWork/HowWeWork";
import Partners from "./components/Partners/Partners";
import HomePageNews from "./components/HomePageNews/HomePageNews";
import Contact from "./components/Contact/Contact";
import CopyrightStrip from "./components/CopyrightStrip/CopyrightStrip";

function Home() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])

    return (
        <>
            <HomePageHeader />
            <WhoAreWe />
            <HowWeWork />
            <Partners />
            <HomePageNews />
            <Contact />
            <CopyrightStrip />

            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
                    integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
                    crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js"
                    integrity="sha384-mQ93GR66B00ZXjt0YO5KlohRA5SY2XofN4zfuZxLkoj1gXtW8ANNCe9d5Y3eG5eD"
                    crossOrigin="anonymous"></script>
        </>
    );
}

export default Home;
