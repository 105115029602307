import React from 'react'
import {HashLink as Link} from 'react-router-hash-link'

const NewsNavigation = () => {
    return (
        <div className="container-fluid main-header-menu-container">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="main-menu-link-container align-self-center">
                            <Link className={'main-menu-link'} to={'/'}>Főoldal</Link>
                        </div>
                        <div className="main-menu-link-container align-self-center">
                            <Link className={'main-menu-link'} to={'/#hirek'}>Hírek</Link>
                        </div>
                        <div className="main-menu-link-container align-self-center">
                            <Link className={'main-menu-link'} to={'/#kapcsolat'}>Alkoss velünk</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsNavigation