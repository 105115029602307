import React, {useEffect} from 'react'
import './news.scss'
import NewsNavigation from "./NewsNavigation";
import CopyrightStrip from "../../components/CopyrightStrip/CopyrightStrip";

const Article4 = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            <div className="container-fluid news-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/news/flashmob-vagy-fellepes.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt=""/>
                    </div>
                </div>
            </div>

            <NewsNavigation />

            <div className="container article-container my-5 py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>Flashmob vagy fellépés?</h1>
                        <p>Tapasztalataink azt mutatják, hogy a megrendelők gyakran tévesen vélekednek a flashmob
                            fogalmáról,
                            miközben fellépésre gondolnak. Ezt most segítünk letisztázni mindenki számára.</p>
                        <p>A fellépés tulajdonképpen egy színpadi produkció, mely esetén természetesen a tényleges
                            fizikai színpad
                            nem feltétel. A közönség teljes mértékben tudatában van, hogy mikor kezdődik el, és mik a
                            körülmények.</p>
                        <p>Ezzel ellentétben a flashmob váratlan kezdetű, gyakran a lehető leghétköznapibb helyszínen
                            történik,
                            mint például egy pláza, nézőtér, vagy állomás. A járókelők soha nem tudják ki lesz a
                            következő, aki
                            csatlakozik a táncba, vagy mi lesz a folytatás.</p>
                        <p>Ha nektek már van egy elképzelésetek flashmobra vagy fellépésre, írjatok nekünk, és
                            együtt megvalósítjuk!</p>
                    </div>
                </div>
            </div>

            <CopyrightStrip />
        </>
    )
}

export default Article4