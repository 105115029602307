import React, {useEffect} from 'react'
import './news.scss'
import NewsNavigation from "./NewsNavigation";
import CopyrightStrip from "../../components/CopyrightStrip/CopyrightStrip";

const Article3 = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            <div className="container-fluid news-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/news/az-md-dance-company-tancos-elete.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt="" />
                    </div>
                </div>
            </div>

            <NewsNavigation />

            <div className="container article-container my-5 py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>Az MD Dance Company táncos élete.</h1>
                        <p>Izgalmas előadások, változatos fellépések, rivalda fény, pompa és csillogás. A közönség így
                            látja az
                            MD Dance Company táncosainak mindennapjait. Mindezek hátterében viszont kemény munka,
                            szervezés,
                            ügyfelekkel való napi egyeztetés zajlik, hogy teljes mértékben megvalósítsuk minden
                            megrendelőnk
                            legmerészebb vágyait.</p>
                        <p>A koncentrációt és pontosságot se hagyjuk ki a sorból, hiszen egyes műsorunkat akár milliók
                            nézik
                            élő adásból, mint például a 2019-es UEFA Women Champion döntő megnyitóját, vagy a ZTE-Milan
                            barátságos
                            mérkőzés félidei műsorát. Ilyenkor nincs helye a lámpaláznak és a hibáknak. Gyakran az
                            időjárási
                            körülmények sem kímélnek minket. Se eső, se hó, se pedig a nyári tűzőnap és olvasztó meleg a
                            2022-es
                            Sziget Fesztiválon, semmi nem gátol abban, hogy a legjobbat nyújtsuk teljes odaadással.</p>
                        <p>Alkalmanként nehezített a terep számunkra, amikor nincsen biztosított és elkerített fellépő
                            terület.
                            Ez különösen a [flashmob]oknál fordul elő. A plázák, terek és emberektől nyüzsgő helyszínek
                            egy
                            szempillantás alatt képesek átváltozni a világot jelentő deszkákká. A nézők sose tudhatják,
                            vajon ki a
                            következő beépített ember, aki csatlakozik a műsorhoz, persze számunkra is kiszámíthatatlan,
                            hogy
                            átsétál-e egy járókelő a térformánk közepén, mint ahogyan ez megesett egy Huawei üzletet
                            megnyitó
                            flashmobon.</p>
                        <p>Mindezek a tényezők a közönség számára láthatatlanok, csak azt az energiától duzzadó
                            varázslatos
                            produkciókat látják, melyet mi hosszú órákkal töltött gyakorlás és évtizedes tapasztalatnak
                            köszönhetően
                            életre keltünk. Ebben minden alkalommal a legnagyobb szerepet az ügyfeleink játszák, akiknek
                            fantáziája
                            és zökkenőmentes kommunikációja hatalmas mértékben segíti a munkánk.</p>
                    </div>
                </div>
            </div>

            <CopyrightStrip />
        </>
    )
}

export default Article3