import React from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import './ThankYou.scss'

const ThankYou = () => {
    return (
        <>
            <div className="container-fluid thank-you-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/thank-you.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt=""/>
                    </div>
                </div>
            </div>

            <div className="container-fluid main-header-menu-container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="main-menu-link-container align-self-center">
                                <Link className={'main-menu-link'} to={'/'}>Főoldal</Link>
                            </div>
                            <div className="main-menu-link-container align-self-center">
                                <Link className={'main-menu-link'} to={'/#hirek'}>Hírek</Link>
                            </div>
                            <div className="main-menu-link-container align-self-center">
                                <Link className={'main-menu-link'} to={'/#kapcsolat'}>Alkoss velünk</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container thank-you py-5 my-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Köszönjük az érdeklődésed!</h1>
                        <h4>Kedves Érdeklődő!</h4>

                        <p className="mb-0">Köszönjük, hogy írtál nekünk!</p>
                        <p>Kollégánk hamarosan felveszi veled a kapcsolatot.</p>

                        <p className="mb-2">Üdvözlettel:<br/>
                            MD Team</p>
                        <div className="social-links justify-content-center mb-3">
                            <a className="social-link-container" href="https://www.facebook.com/md.dancecompany.hu/"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a className="social-link-container" href="https://www.instagram.com/md_dancecompany/"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a className="social-link-container" href="https://www.youtube.com/MDDanceCompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-youtube"></i>
                            </a>
                            <a className="social-link-container" href="https://www.tiktok.com/@md_dancecompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-tiktok"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <Link className={"back-to-home"} to={'/'}> <i className="bi bi-arrow-left"></i>Vissza a főoldalra</Link>
                        {/*<a className="back-to-home" href="./index.html"></a>*/}
                    </div>
                </div>
            </div>

            <div className="container-fluid copyright-block mt-5">
                <div className="row h-100">
                    <div className="col-12 text-center align-self-center">
                        <p className="m-0">© 2023. Minden jog fenntartva. MD Dance Company</p>
                    </div>
                </div>
            </div>

            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
                    integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
                    crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js"
                    integrity="sha384-mQ93GR66B00ZXjt0YO5KlohRA5SY2XofN4zfuZxLkoj1gXtW8ANNCe9d5Y3eG5eD"
                    crossOrigin="anonymous"></script>
        </>
    )
}

export default ThankYou;