import React from 'react'

const Partners = () => {
    return (
        <>
            <div className="container partners my-5 py-5">
                <div className="row">
                    <div className="col-12 col-md-6 align-self-center">
                        <h1>Akikkel<br/>Eddig<br/>Dolgoztunk</h1>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1 mt-5 mt-md-0">
                        <div className="container px-0">
                            <div className="row g-3">
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/uefa-supercup-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/hm-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/uefa-woman-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/kpmg-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/huawei-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/garnier-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/zte-logo.png" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/xtrm-logo.svg" alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="col-6 partners-single-container wow animate__animated animate__fadeInUp">
                                    <div className="container">
                                        <img src="/img/nemzeti-hotel-logo.jpeg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partners;