import React from 'react'

const CopyrightStrip = () => {
    return (
        <>
            <div className="container-fluid copyright-block mt-5">
                <div className="row h-100">
                    <div className="col-12 text-center align-self-center">
                        <p className="m-0">© 2023. Minden jog fenntartva. MD Dance Company</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CopyrightStrip;