import React, {useRef, useState} from 'react'
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom';

const Contact = () => {
    const navigate = useNavigate()
    const form = useRef();
    const [loading, setLoading] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)

        emailjs.sendForm('service_0lvcirm', 'template_kt0c2ft', form.current, '-UdiWzWJ98juAY5Fv')
            .then((result) => {
                console.log(result.text);
                navigate('/thank-you', {replace: true});
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <div className="container contact my-5 py-5" id="kapcsolat">
                <div className="row">
                    <div className="col-12 col-md-6 align-self-center">
                        <h1>Kapcsolat</h1>
                        <p className="mb-0"><strong>MD Dance Studio</strong></p>
                        <p className="mb-0"><strong>Cím:</strong> Budapest 5. Kerület, Semmelweis u. 1-3,</p>
                        <p className="mb-0"><strong>E-mail cím:</strong> <a
                            href="mailto:md.dancecompany.hu@gmail.com">md.dancecompany.hu@gmail.com</a></p>
                        <p className="mb-0"><strong>Telefon:</strong> +36306336682</p>
                        <div className="social-links">
                            <a className="social-link-container" href="https://www.facebook.com/md.dancecompany.hu/"
                               target="_blank" rel="noreferrer">
                                <i className="bi-facebook"></i>
                            </a>
                            <a className="social-link-container" href="https://www.instagram.com/md_dancecompany/"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a className="social-link-container" href="https://www.youtube.com/MDDanceCompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-youtube"></i>
                            </a>
                            <a className="social-link-container" href="https://www.tiktok.com/@md_dancecompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-tiktok"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1 mt-3 mt-md-0">
                        <div className="container contact-container wow animate__animated animate__fadeInUp py-3">
                            <div className={loading ? 'd-inline-block contact-loading' : 'contact-loading'}>
                                <div className="contact-loading-spinner">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="user_name" name="user_name"
                                           placeholder="Full Name"/>
                                    <label htmlFor="name">Név</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="user_email" name="user_email"
                                           placeholder="name@example.com"/>
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="user_phone" name="user_phone"
                                           placeholder="06 00 000 0000"/>
                                    <label htmlFor="phone">Telefonszám</label>
                                </div>
                                <div className="form-floating mb-3">
                    <textarea className="form-control" placeholder="Leave a comment here" id="user_message" name="user_message"
                              style={{height: 150}}></textarea>
                                    <label htmlFor="message">Megjegyzés</label>
                                </div>
                                <button type="submit" className="btn">Küldés</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;