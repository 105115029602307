import React from 'react'
import {Link} from 'react-router-dom'
import './PageNotFound.scss'

const PageNotFound = () => {
    return (
        <>
            <div className="container page-not-found py-5 mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={'/img/md-logo-black.png'} alt={'MD Dance Company logo'}/>
                        <h1>Whoups!</h1>
                        <h2>Ez az oldal nem elérhető!</h2>
                        <h4>Keress minket az alábbi linkeken keresztül:</h4>
                        <div className="social-links justify-content-center mb-3">
                            <a className="social-link-container" href="https://www.facebook.com/md.dancecompany.hu/"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a className="social-link-container" href="https://www.instagram.com/md_dancecompany/"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a className="social-link-container" href="https://www.youtube.com/MDDanceCompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-youtube"></i>
                            </a>
                            <a className="social-link-container" href="https://www.tiktok.com/@md_dancecompany"
                               target="_blank" rel="noreferrer">
                                <i className="bi bi-tiktok"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <Link className={"back-to-home"} to={'/'}> <i className="bi bi-arrow-left"></i>Vissza a főoldalra</Link>
                    </div>
                </div>
            </div>

            <div className="container-fluid copyright-block fixed-bottom mt-5">
                <div className="row h-100">
                    <div className="col-12 text-center align-self-center">
                        <p className="m-0">© 2023. Minden jog fenntartva. MD Dance Company</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;