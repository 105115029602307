import React, {useEffect} from 'react'
import './news.scss'
import CopyrightStrip from "../../components/CopyrightStrip/CopyrightStrip";
import NewsNavigation from "./NewsNavigation";

const Article1 = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            <div className="container-fluid news-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/news/2023-majus-31-ei-fellepes-info.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt="" />
                    </div>
                </div>
            </div>

            <NewsNavigation />

            <div className="container article-container my-5 py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>2023 Május 31.-ei fellépés info</h1>
                        <p>Sziasztok!</p>
                        <p>Az alábbiakban foglaljuk össze nektek az összes tudnivalót a május 31.-ei fellépést
                            illetően.</p>
                        <p>Próbák időpontja és helyszíne:</p>
                        <ul>
                            <li>2023 Május 25. Csütörtök 15:30-22:30 Budapest , (BVSC academy, 1142 Budapest, Tatai ut.
                                3)
                            </li>
                            <li>2023 Május 26. Péntek 15:30-22:30 Budapest , (BVSC academy, 1142 Budapest, Tatai ut.
                                3)
                            </li>
                            <li>2023 Május 27. Szombat 09:30-18:30 Budapest , (BVSC academy, 1142 Budapest, Tatai ut.
                                3)
                            </li>
                            <li>2023 Május 28. Vasárnap 17:00- 22:30 Budapest, Puskás Aréna</li>
                            <li>2023 Május 29. Hétfő 17:00- 22:30 Budapest, Puskás Aréna</li>
                        </ul>
                        <p>Fellépés napja: 2023 Május 31. Szerda - 17:00-22:00</p>
                        <p>Fellépés helyszíne: Budapest, Puskás Aréna </p>
                        <p>A munka a szerződést, bejelentést valamit a biztosítást tartalmazza, amelyre 30 napos
                            kifizetést
                            vállalunk. Biztosítva lesz továbbá a teljes fellépő ruházat.</p>
                        <p>Amennyiben érdekes számodra a munka és kaptál előzetes meghívót, várjuk a jelentkezésed
                            e-mailben:
                            <a href="mailto:md.dancecompany.hu@gmail.com">md.dancecompany.hu@gmail.com</a></p>
                        <p>Ha nem kaptál előzetes meghívót, de szívesen csatlakoznál, várunk a nyílt castingra, amelynek
                            részleteihez <a href="./md-tancos-casting.html">[kattints ide]</a>!</p>
                        <p>Üdvözlettel,<br/>
                            MD Team</p>
                    </div>
                </div>
            </div>

            <CopyrightStrip />
        </>
    )
}

export default Article1