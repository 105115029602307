import React from 'react'
import {Link} from 'react-router-dom'

const HomePageNews = () => {
    return (
        <>
            <div className="container news-container my-5 py-5" id="hirek">
                <div className="row">
                    <div className="col-12 col-md-8 order-2 order-md-1 mt-3 mt-md-0">
                        <div className="row g-2 justify-content-center">
                            <div className="col-12 col-lg-6">
                                <div className="container article-container wow animate__animated animate__fadeInUp">
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="article-details-container d-flex h-100">
                                                <div className="article-image-container align-self-center">
                                                    <img src="/img/news/all-style-dance-battle_thumb.jpg" alt=""/>
                                                </div>
                                                <div className="article-text-container">
                                                    <p className="article-date">2023. február</p>
                                                    <h4>All Style Dance Battle</h4>
                                                    <p className="mb-0">
                                                        <Link to={'/news/all-style-dance-battle'}>
                                                            Tovabb a cikkhez <i className="bi bi-arrow-right-short"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="container article-container wow animate__animated animate__fadeInUp">
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="article-details-container d-flex h-100">
                                                <div className="article-image-container align-self-center">
                                                    <img src="/img/news/md-tancos-casting_thumb.jpg" alt=""/>
                                                </div>
                                                <div className="article-text-container">
                                                    <p className="article-date">2023. január</p>
                                                    <h4>MD Táncos Casting</h4>
                                                    <p className="mb-0">
                                                        <Link to={'/news/md-tancos-casting'}>
                                                            Tovabb a cikkhez <i className="bi bi-arrow-right-short"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="container article-container wow animate__animated animate__fadeInUp">
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="article-details-container d-flex h-100">
                                                <div className="article-image-container align-self-center">
                                                    <img src="/img/news/az-md-dance-company-tancos-elete_thumb.jpg"
                                                         alt=""/>
                                                </div>
                                                <div className="article-text-container">
                                                    <p className="article-date">2023. január</p>
                                                    <h4>Az MD táncos élete.</h4>
                                                    <p className="mb-0">
                                                        <Link to={'/news/az-md-dance-company-tancos-elete'}>
                                                            Tovabb a cikkhez <i className="bi bi-arrow-right-short"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="container article-container wow animate__animated animate__fadeInUp">
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="article-details-container d-flex h-100">
                                                <div className="article-image-container align-self-center">
                                                    <img src="/img/news/flashmob-vagy-fellepes_thumb.jpg" alt=""/>
                                                </div>
                                                <div className="article-text-container">
                                                    <p className="article-date">2023. január</p>
                                                    <h4>Flashmob vagy fellépés?</h4>
                                                    <p className="mb-0">
                                                        <Link to={'/news/flashmob-vagy-fellepes'}>
                                                            Tovabb a cikkhez <i className="bi bi-arrow-right-short"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 align-self-center text-start text-md-end order-1 order-md-2">
                        <h1>Hírek</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageNews;