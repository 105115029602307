import React, {useEffect} from 'react'
import './news.scss'
import NewsNavigation from "./NewsNavigation";
import CopyrightStrip from "../../components/CopyrightStrip/CopyrightStrip";

const Article2 = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            <div className="container-fluid news-header">
                <div className="row">
                    <div className="col-12">
                        <img className="background-image" src="/img/news/all-style-dance-battle.jpg" alt=""/>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt=""/>
                    </div>
                </div>
            </div>

            <NewsNavigation/>

            <div className="container article-container my-5 py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>All Style Dance Battle</h1>
                        <p>Sziasztok, Kedves Érdeklődők!</p>
                        <p>Szeretettel meghívunk benneteket egy estére, ahol a táncé a főszerep. Az eseményen a Gáláns
                            Indiánok
                            (Galant Indies) című francia zenés-táncos dokumentum film lesz megtekinthető, amelyet a
                            helyszínen egy
                            all-stlye tánc párbaj fog követni 100.000 Ft-os fődíjjal.</p>
                        <h4 className="mt-4">FILM VETÍTÉS</h4>
                        <p>Galant Indies (2020) - A legendás párizsi Opéra Bastille színpadán 30, nem hagyományos műfajú
                            táncos
                            kelti új életre egy teljesen új stílusban Jean-Philippe Rameau barokk remekművét, a Les
                            Indes
                            galantes-t,
                            dinamikus képet adva a mérföldkőnek számító operáról. A dokumentumfilm ennek készületeit
                            mutatja be
                            teljes
                            testközelből.</p>
                        <p><strong>Vetítés kezdete:</strong> 19:30</p>
                        <h4 className="mt-4">ALL-STYLE DANCE BATTLE</h4>
                        <p>Akinkek megtetszettek a filmben látott táncstílusok, nem csak a vásznon, hanem élőben, egy
                            autentikus
                            környezetben jobban elmerülne ezek világában, nekik a filmvetítést követően egy táncpárbajt
                            lesz
                            lehetőségük megnézni, vagy akár indulni rajta. Senkinek sincs szüksége külön nevezésre, a
                            belépését
                            követően bárk a párbaj részvevője lehet, ha szeretne.</p>
                        <h4 className="mt-4">ZSŰRIK</h4>
                        <ul>
                            <li>Android18 (Jossa-Pál Adrienn)</li>
                            <li>Pizsy (Fazekas Róbert)</li>
                            <li>Father Daniel Elle (Móritz Dániel)</li>
                            <li>DJ - Soldier P (Katona Péter)</li>
                            <li>MC - Bboy Ren (Somoskői Mihály)</li>
                        </ul>
                        <h4 className="mt-4">PÁRBAJ SELEJTEZŐ</h4>
                        <p>A selejtező egy 30 perces jamelés lesz, ami közben a Dj a zenei stílusokat váltogatni
                            fogja, hogy mindenki számára legyen, amire otthonosan tud táncolni. Persze izgalmasabb a
                            táncosoknak
                            mindenre kipróbálni magukat. A jamelés ideje alatt 3 cypher lesz kialakítva, melyeknél 1-1
                            zsűri lesz
                            jelen. Az indulók szabadon mozoghatnak ezek között, hogy minden zsűri számára megmutassák
                            magukat,
                            növelve így az esélyét a továbbjutásra.</p>
                        <p>A 30 perc elteltével a zsűrik a kiválasztottjaiknak adnak egy-egy (illetve valamelyikük
                            kettő) "párbaj
                            kártyát", ami azt jelenti, hogy ők bejutottak a top 4-be, és párbajozhatnak a fődíjért.</p>
                        <h4 className="mt-4">PÁRBAJ</h4>
                        <p>A párbaj során minden körben 2 zenére mutathatják meg tudásukat a táncosok. A 4 kiválaszottak
                            sorsolás alapján egyenes kieséssel párbajoznak egymással, így kerül ki a végén egy győztes,
                            aki
                            hazaviheti a 75.000 Ft-ot és további 25.000 Ft tárgyi nyereményt, azaz összesen
                            <strong>100.000 Ft-os fődíjat</strong>!</p>
                        <h4 className="mt-4">JEGYEK</h4>
                        <ul>
                            <li>Elővételben - 3000.Ft (hamarosan)</li>
                            <li>Helyszínen - 3500.Ft</li>
                        </ul>

                        <h4 className="mt-4">MENETREND</h4>
                        <ul>
                            <li>19:30 - Filmvetítés</li>
                            <li>21:30 - Párbaj selejtező kezdete</li>
                            <li>22:00 - Top 4 párbaj kezdete</li>
                            <li>23:00 - Esemény vége</li>
                        </ul>
                        <p>Gyertek és csatlakozzatok hozzánk a freestlye táncok világába!</p>
                    </div>
                </div>
            </div>

            <CopyrightStrip/>
        </>
    )
}

export default Article2