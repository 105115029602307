import React from 'react'

const WhoAreWe = () => {
    return (
        <>
            <div className="container who-are-we-container my-5 py-5">
                <div className="row">
                    <div className="col-12 col-md-6 align-self-center">
                        <h1>MD Dance<br/>Company</h1>
                        <p>Teljeskörű táncszolgáltatást nyújtunk magán személyeknek és cégeknek.
                            Kisebb projektektől a nagyobb volumenű színpadi produkciókig, kreatív,
                            látványos műsorokkal és reklámfelvételekkel szolgálunk. Célunk, hogy mindig
                            pontosan valósítsuk meg a megrendelő elképzeléseit.</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1 mt-3 mt-md-0">
                        <div className="video-container wow animate__animated animate__fadeInUp">
                            <video className="box-shadow-black" autoPlay muted loop playsInline>
                                <source src="/vertical-video.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoAreWe