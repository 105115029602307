import React from 'react'

const HowWeWork = () => {
    return (
        <>
            <div className="container how-we-work-container py-5 my-5">
                <div className="row">
                    <div className="col-12 col-md-6 order-2 order-md-1 mt-3 mt-md-0">
                        <div className="container px-0">
                            <div className="row">
                                <div className="col-11 col-sm-10">
                                    <div
                                        className="container how-we-work-container-single mb-3 wow animate__animated animate__fadeInUp">
                                        <div className="d-flex">
                                            <div className="number-container align-self-center">
                                                <span>1</span>
                                            </div>
                                            <div className="text-container">
                                    <span><b>Brief - Debrief:</b> megismerni a rendezvény célját, menetét, közönségét,
                                        valamint a költségvetését.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-sm-10 offset-1 offset-sm-2">
                                    <div
                                        className="container how-we-work-container-single mb-3 wow animate__animated animate__fadeInUp">
                                        <div className="d-flex">
                                            <div className="number-container align-self-center">
                                                <span>2</span>
                                            </div>
                                            <div className="text-container">
                                    <span><b>Kreatív koncepció:</b> Várjuk az ügyfelek elképzeléseit, abban az esetben,
                                        ha segítség kell, bármikor készen állunk segíteni bármilyen kreatív ötlet
                                        kitalálásában.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-sm-10">
                                    <div
                                        className="container how-we-work-container-single mb-3 wow animate__animated animate__fadeInUp">
                                        <div className="d-flex">
                                            <div className="number-container align-self-center">
                                                <span>3</span>
                                            </div>
                                            <div className="text-container">
                                    <span><b>Szerződés:</b> Ha megállapodtunk a részletekben, akkor sor kerülhet a
                                        szerződés megkötésére.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-sm-10 offset-1 offset-sm-2">
                                    <div
                                        className="container how-we-work-container-single mb-3 wow animate__animated animate__fadeInUp">
                                        <div className="d-flex">
                                            <div className="number-container align-self-center">
                                                <span>4</span>
                                            </div>
                                            <div className="text-container">
                                    <span><b>Próbaidőszak:</b> A projectre való felkészülés, melynek során az ügyfél bármikor
                                        megtekintheti a folyamatot és az elkészült anyagot. Akár kisebb változtatásra
                                        is van lehetőség indokolt esetben.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-sm-10">
                                    <div
                                        className="container how-we-work-container-single mb-3 wow animate__animated animate__fadeInUp">
                                        <div className="d-flex">
                                            <div className="number-container align-self-center">
                                                <span>5</span>
                                            </div>
                                            <div className="text-container">
                                    <span><b>Teljesítés:</b> A előadás napja, amikor maximális energiával és
                                        lelkesedéssel lépünk fel.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12 col-md-6 col-lg-5 offset-lg-1 text-start text-md-end align-self-center order-1 order-md-2">
                        <h1>Hogyan<br/>Dolgozunk</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowWeWork;