import React from 'react'

const HomePageHeader = () => {
    return (
        <>
            <div className="container-fluid main-header px-0">
                <div className="row video-container-row">
                    <div className="col-12 text-center">
                        <video autoPlay muted loop playsInline>
                            <source src="/home-video.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="row logo-container-row align-content-center">
                    <div className="col-12 text-center">
                        <img src="/img/md-logo.png" alt=""/>
                    </div>
                </div>
            </div>
            <div className="container-fluid main-header-menu-container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="main-menu-link-container align-self-center">
                                <a className="main-menu-link" href="/">Főoldal</a>
                            </div>
                            <div className="main-menu-link-container align-self-center">
                                <a className="main-menu-link" href="#hirek">Hírek</a>
                            </div>
                            <div className="main-menu-link-container align-self-center">
                                <a className="main-menu-link" href="#kapcsolat">Alkoss velünk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageHeader