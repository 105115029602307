import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import Home from './Home';
import ThankYou from "./pages/ThankYou/ThankYou";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Article1 from "./pages/news/2023-majus-31-ei-fellepes-info";
import Article2 from "./pages/news/all-style-dance-battle";
import Article3 from "./pages/news/az-md-dance-company-tancos-elete";
import Article4 from "./pages/news/flashmob-vagy-fellepes";
import Article5 from "./pages/news/md-tancos-casting";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter primary={false}>
        <Routes>
            <Route path={''} element={<Home />}/>
            <Route path={'/thank-you'} element={<ThankYou />}/>
            <Route path={'/news/2023-majus-31-ei-fellepes-info'} element={<Article1 />}/>
            <Route path={'/news/all-style-dance-battle'} element={<Article2 />}/>
            <Route path={'/news/az-md-dance-company-tancos-elete'} element={<Article3 />}/>
            <Route path={'/news/flashmob-vagy-fellepes'} element={<Article4 />}/>
            <Route path={'/news/md-tancos-casting'} element={<Article5 />}/>
            <Route path={'*'} element={<PageNotFound />}/>
        </Routes>
    </BrowserRouter>
);
